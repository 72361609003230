// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-deposits-js": () => import("./../../../src/pages/deposits.js" /* webpackChunkName: "component---src-pages-deposits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquire-js": () => import("./../../../src/pages/inquire.js" /* webpackChunkName: "component---src-pages-inquire-js" */),
  "component---src-pages-loans-js": () => import("./../../../src/pages/loans.js" /* webpackChunkName: "component---src-pages-loans-js" */),
  "component---src-pages-programs-js": () => import("./../../../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-terms-and-condition-js": () => import("./../../../src/pages/terms-and-condition.js" /* webpackChunkName: "component---src-pages-terms-and-condition-js" */)
}

